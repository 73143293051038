




















































































































































































































































































import { MonitoringAPI } from '@/app/api';
import { ConfirmModal, HtmlModal, ProcessedSampleView, SvgImage, TwButton, WizardTabs } from '@/app/components';
import {
    useAxios,
    useErrors,
    useExecutionErrors,
    useModelBrowser,
    useQuery,
    useResult,
    useSockets,
} from '@/app/composable';
import { useRouter } from '@/app/composable/router';
import { Model } from '@/app/interfaces';
import store from '@/app/store';
import { HarvesterSourceType, StatusCode } from '@/modules/data-checkin/constants';
import { Status } from '@/modules/data-model/constants';
import {
    ArrowCircleUpIcon,
    ChevronLeftIcon,
    ChevronRightIcon,
    ExclamationCircleIcon,
    LightningBoltIcon,
} from '@vue-hero-icons/outline';
import {
    Ref,
    computed,
    defineComponent,
    onBeforeUnmount,
    onMounted,
    onUnmounted,
    ref,
    watch,
} from '@vue/composition-api';
import { OrbitSpinner } from 'epic-spinners';
import * as R from 'ramda';
import { JobsAPI, ModelAPI } from '../../api';
import { LoadingSampleRunModal, StepCompletionModal, WizardActions } from '../../components';
import { useMappingConfiguration, useMappingMigration, useSampleRun, useStep } from '../../composable';
import GET_JOB from '../../graphql/getJob.graphql';
import { StepStats } from '../../types/step-stats.interface';
import MappingConfiguration from './Configuration.vue';
import MappingInfo from './Info.vue';
import MappingReview from './Review.vue';
import { AlternateNaming, FieldConfiguration, MappingConfig } from './mapping.types';
import { S } from '@/app/utilities';

export default defineComponent({
    name: 'Mapping',
    metaInfo() {
        return { title: `Mapping${(this as any).job ? ` for: ${(this as any).job.name}` : ''}` };
    },
    props: {
        id: {
            type: [Number, String],
            required: true,
        },
        step: {
            type: String,
            required: false,
        },
        queryParams: {
            type: String,
            default: '{}',
        },
    },
    components: {
        ConfirmModal,
        MappingConfiguration,
        MappingInfo,
        OrbitSpinner,
        TwButton,
        WizardTabs,
        MappingReview,
        StepCompletionModal,
        HtmlModal,
        SvgImage,
        ChevronLeftIcon,
        ChevronRightIcon,
        ExclamationCircleIcon,
        ArrowCircleUpIcon,
        WizardActions,
        LightningBoltIcon,
        LoadingSampleRunModal,
        ProcessedSampleView,
    },
    setup(props, { root }) {
        const router = useRouter();
        const isMacOS = window.navigator.userAgent.indexOf('Mac OS') !== -1;
        const isStreamingJob = computed(
            () =>
                !!job.value?.dataCheckinJobSteps?.find(
                    (step: any) =>
                        step.dataCheckinStepType?.name === 'harvester' &&
                        [
                            HarvesterSourceType.Kafka,
                            HarvesterSourceType.ExternalKafka,
                            HarvesterSourceType.MQTT,
                            HarvesterSourceType.ExternalMQTT,
                        ].includes(step.configuration.source),
                ),
        );
        const { loading, error, exec } = useAxios(true);
        const { checkGQLAuthentication } = useErrors(root.$route);
        const jobId = parseInt(`${props.id}`, 10);
        const steps = ref([
            { title: 'Info', key: 'info' },
            { title: 'Configuration', key: 'configuration' },
            { title: 'Review Rules', key: 'rules' },
            { title: 'Confirm', key: 'confirm' },
        ]);
        const finalTabNumber = ref<number>(3);
        const mappingRef = ref<HTMLElement | null>(null);
        const showFinalizeModal = ref<boolean>(false);
        const restartedStep = ref<boolean>(false);
        const nextStep = ref<any>(null);
        const loadingFinalization = ref<boolean>(false);
        const loadingStepKey = ref<boolean>(true);
        const resettingMapping = ref<boolean>(false);
        const stats = ref<StepStats | null>(null);
        const validationTimestamp = ref<Date>(new Date());
        const processedSample = ref<any>(null);
        const previousProcessedSample = ref<any>(null);
        const mappingConfiguration = ref<any>(null);
        const { errorMessage } = useExecutionErrors();
        const lazyFieldChanged = ref<boolean>(false);
        const showResetAliasesModal = ref(false);

        const stepKey = ref<string>(root.$route.params.step ? (root.$route.params.step as any) : steps.value[0].key);

        const activeTab = computed({
            get: () => {
                return (
                    steps.value.findIndex(
                        (step: { key: string }) => step.key.toLowerCase() === stepKey.value.toLowerCase(),
                    ) || 0
                );
            },
            set: (newStep: number) => {
                const step = steps.value[newStep];
                stepKey.value = step.key;
                router.push({
                    name: 'mapping',
                    params: { id: String(props.id), step: step.key.toLowerCase(), queryParams: props.queryParams },
                });
            },
        });

        // Fetch job information
        const { loading: jobLoading, error: jobError, result, onError, refetch } = useQuery(
            GET_JOB,
            { id: jobId },
            { fetchPolicy: 'no-cache' },
        );
        onError(checkGQLAuthentication);

        const job = useResult(result, null, (data: any) => data.job);

        // Fetch mapping configuration
        const mapping = ref<any>(null);
        const {
            isConfigEmpty,
            isFinalized,
            getNextStep,
            updateAssetAfterFailedStep,
            canRestart,
            lockDcj,
            getPreviousProcessedSample,
        } = useStep(mapping, job, root, props.queryParams);

        const sample = computed(() => job.value?.sample);

        const originalConfiguration: Ref<MappingConfig> = computed(() => {
            return mapping.value && !R.isEmpty(mapping.value.configuration) && !R.isNil(mapping.value.configuration)
                ? {
                      ...mapping.value.configuration,
                      alternateNaming: mapping.value.configuration.alternateNaming ?? AlternateNaming.None,
                  }
                : {
                      domain: null,
                      standard: null,
                      concept: null,
                      multiple: false,
                      basePath: null,
                      alternateNaming: AlternateNaming.None,
                      versions: {
                          editor: '1.1.0',
                          transformationEngine: process.env.VUE_APP_TRANSFORMER_VERSION as string,
                          model: null,
                          predictionEngine: null,
                      },
                      fields: [],
                      customizedConcepts: {},
                  };
        });

        const removeFields = (configuration: any, fields: string[]) => {
            const clonedConfig = R.clone(configuration);
            fields.forEach((field) => {
                clonedConfig.fields = clonedConfig.fields.map((f: any) => R.omit([field], f));
            });

            return clonedConfig;
        };

        const hasChanges = computed(
            () =>
                lazyFieldChanged.value ||
                JSON.stringify(originalConfiguration.value) !== JSON.stringify(configuration.value),
        );

        const basePath = computed(() => configuration.value?.basePath?.split('||')?.slice(1)); // basePath has format res||<field1>||<field2>||..., so we ignore res
        const {
            domains,
            validationErrorPerId,
            configuration,
            resetMapping,
            initEmptyField,
            validate,
            setConcept,
            refreshMapping,
            getValidConfiguration,
            rootConcept,
        } = useMappingConfiguration(originalConfiguration, sample, basePath);
        const {
            model,
            concepts,
            getConceptFromId,
            domainLoading,
            modelsLoading,
            conceptsLoading,
            restrictingConcept,
            resetModelBrowser,
            refreshModels,
            getModelFromId,
        } = useModelBrowser();

        const modelReady = computed(
            () =>
                !modelsLoading.value &&
                !domainLoading.value &&
                model.value &&
                configuration.value?.domain?.id &&
                model.value.uid === getModelFromId(configuration.value?.domain?.id)?.uid,
        );

        const domainSelected = computed<boolean>(() => configuration.value && !!configuration.value.domain);

        /**
         * Detects if we need to inform the user to do a mapping upgrade
         */
        const mappingNeedsUpgrade = computed(() => {
            if (!domainSelected.value || isFinalized.value) {
                return false;
            }

            if (domainSelected.value && !isFinalized.value && configuration.value?.domain?.id) {
                for (let d = 0; d < domains.value.length; d++) {
                    const domain = domains.value[d];
                    if (domain.id === configuration.value?.domain?.id) {
                        return false;
                    }
                }
            }
            // if no matching domain is found in the iteration above
            // an upgrade is needed
            return true;
        });

        const mappedConceptsExist = computed(() => {
            const unmappedConcepts = configuration.value.fields.filter((obj: any) => {
                return !('target' in obj && 'id' in obj.target && obj.target.id);
            });
            return unmappedConcepts.length !== configuration.value.fields.length;
        });

        const showConfirmModalForClearingMapping = ref(false);
        const allowNext = computed(
            () => !!configuration.value?.domain && !!configuration.value?.concept && !pageLoading.value,
        );

        const isValid = computed<boolean>(
            () => configuration.value.fields.filter((obj: any) => obj.temp && obj.temp.invalid).length === 0,
        );

        const invalidMappingsFixed = computed(() =>
            R.all(
                (code) => R.isNil(code),
                R.pluck('errorCode', stats.value?.latestExecutionStats?.statsPerField ?? []),
            ),
        );

        const pageLoading = computed(() => {
            return (
                loadingFinalization.value ||
                loading.value ||
                domainLoading.value ||
                jobLoading.value ||
                modelsLoading.value ||
                conceptsLoading.value ||
                resettingMapping.value
            );
        });

        const next = async () => {
            if (activeTab.value === 0) {
                // If is not locked/saved, then confirm and save
                if (
                    originalConfiguration.value.domain &&
                    (originalConfiguration.value.domain?.id !== configuration.value.domain?.id ||
                        JSON.stringify(originalConfiguration.value.standard) !==
                            JSON.stringify(configuration.value.standard) ||
                        originalConfiguration.value.concept?.id !== configuration.value.concept?.id)
                ) {
                    showConfirmModalForClearingMapping.value = true;
                    return;
                }

                // If previous alternate naming was alias, then confirm reset aliases
                if (
                    originalConfiguration.value.alternateNaming &&
                    configuration.value.alternateNaming &&
                    originalConfiguration.value.alternateNaming === AlternateNaming.Alias &&
                    configuration.value.alternateNaming !== AlternateNaming.Alias
                ) {
                    showResetAliasesModal.value = true;
                    return;
                }

                if (mapping.value.status === StatusCode.Update) await refreshMapping();

                // If there is already a processed sample (e.g. after cloning),
                // reset it if the number of mapped fields changed after prediction
                if (processedSample.value) {
                    const initialMappings = mapping.value.configuration.fields.filter((obj: any) => !!obj?.target?.id);
                    const afterPredictionMappings = configuration.value.fields.filter((obj: any) => !!obj?.target?.id);
                    if (initialMappings.length !== afterPredictionMappings.length) processedSample.value = null;
                }
            }

            if (configuration.value.fields.length === 0) await resetMapping();

            if (activeTab.value !== 0 && !mappedConceptsExist.value) {
                (root as any).$toastr.e('At least one field must be mapped', 'Failed');
                return;
            }

            if (activeTab.value === 1) {
                if (mappingConfiguration.value) mappingConfiguration.value.clearSelection();

                const valid = validate();
                if (!valid) {
                    (root as any).$toastr.e(
                        'Please address the validation errors before proceeding to the next step',
                        'Validation Failed',
                    );
                    return;
                }

                // execute sample run before proceeding to the Review Rules tab
                if (!processedSample.value && !skipSampleRun.value) {
                    runOnSample();
                    return;
                }
            }

            // on next save always
            if (activeTab.value !== 1) save(false);

            // Move to next tab
            activeTab.value += 1;
        };
        const previous = () => {
            if (mappingConfiguration.value && activeTab.value === 1) mappingConfiguration.value.clearSelection();
            activeTab.value -= 1;
        };

        const save = async (notify: boolean = true) => {
            try {
                configuration.value.basePath = job?.value?.config?.basePath || null;
                configuration.value.multiple = job?.value?.config?.multiple || false;

                const payload: any = {
                    configuration: configuration.value,
                    serviceVersion: process.env.VUE_APP_TRANSFORMER_VERSION,
                    processedSample: processedSample.value,
                    save,
                };

                await exec(JobsAPI.updateStep(mapping.value.id, payload)).then(
                    (res: any) => (mapping.value = res.data),
                );
                await lockDcj(Number(props.id));
                lazyFieldChanged.value = false;
                if (notify) (root as any).$toastr.s('Mapping configuration saved successfully', 'Success');
            } catch (e) {
                (root as any).$toastr.e('Saving mapping configuration failed', 'Failed');
            }
        };

        const restartStep = async () => {
            try {
                await exec(JobsAPI.restartStep(mapping.value.id)).then((res: any) => {
                    mapping.value = res.data;
                });

                activeTab.value = 1;
                (root as any).$toastr.s(
                    'The configuration of the mapping step is now available for updates.',
                    'Success',
                );
            } catch (e) {
                (root as any).$toastr.e('Revising of the configuration of the mapping step failed', 'Failed');
            }
        };

        const finalize = async () => {
            validate();
            if (isValid.value) {
                loadingFinalization.value = true;
                exec(JobsAPI.updateStep(mapping.value.id, { configuration: configuration.value })).then(() => {
                    getNextStep().then(async (stepTypeResponse: any) => {
                        nextStep.value = stepTypeResponse;

                        /**
                         * If loader step (order = 100) has a different status than "configuration",
                         * it means that the Asset has already been created
                         */
                        if (
                            mapping.value.status === StatusCode.Update &&
                            nextStep.value.order === 100 &&
                            nextStep.value.status !== StatusCode.Configuration
                        ) {
                            await refetch(); // refetch job with its steps after the mapping step is updated
                            if (job.value?.asset && job.value.asset.id) {
                                await updateAssetAfterFailedStep(job.value);
                                await exec(JobsAPI.finalize(mapping.value.id));
                                restartedStep.value = true;
                            } else {
                                (root as any).$toastr.e(
                                    'Failed finalising revised Mapping step due to an error',
                                    'Failed',
                                );
                            }
                        } else {
                            await exec(JobsAPI.finalize(mapping.value.id));
                            showFinalizeModal.value = true;
                        }
                        loadingFinalization.value = false;
                    });
                });
            }
        };

        const cancel = () => {
            root.$router.push({ name: 'data-checkin-jobs', query: JSON.parse(props.queryParams) });
        };

        const showWarningAboutDeprecatedFields = (deprecatedNames: string[]) => {
            if (deprecatedNames.length === 1) {
                (root as any).$toastr.w(
                    `In the latest version of the model you are using, field '${S.sanitizeHtml(
                        deprecatedNames[0],
                    )}' has been deprecated`,
                    'Warning',
                );
            } else if (deprecatedNames.length > 1) {
                (root as any).$toastr.w(
                    `In the latest version of the model you are using, fields '${S.sanitizeHtml(
                        deprecatedNames.join(', '),
                    )}' have been deprecated`,
                    'Warning',
                );
            }
        };

        const clearProcessedSample = () => {
            processedSample.value = null;
        };

        const clearMappingAndProceed = async () => {
            resettingMapping.value = true;
            clearProcessedSample();
            await resetMapping();
            exec(
                JobsAPI.updateStep(mapping.value.id, {
                    configuration: configuration.value,
                    serviceVersion: process.env.VUE_APP_TRANSFORMER_VERSION,
                    processedSample: null,
                }),
            )
                .then(async (res: any) => {
                    mapping.value = res.data;
                    showConfirmModalForClearingMapping.value = false;
                    next();
                })
                .catch(() => {
                    (root as any).$toastr.e('Saving mapping configuration automatically failed', 'Failed');
                })
                .finally(() => {
                    resettingMapping.value = false;
                });
        };

        const clearAliasesAndProceed = () => {
            mapping.value.configuration.fields.forEach((field: any) => {
                field.alias = null;
            });
            mapping.value.configuration.alternateNaming = configuration.value.alternateNaming;
            exec(
                JobsAPI.updateStep(mapping.value.id, {
                    configuration: configuration.value,
                }),
            ).then(async (res: any) => {
                mapping.value = res.data;
                showResetAliasesModal.value = false;
                next();
            });
        };

        const discardChangesAndProceed = () => {
            configuration.value = R.clone(originalConfiguration.value);
            showConfirmModalForClearingMapping.value = false;
            showResetAliasesModal.value = false;
            next();
        };

        const upgradeMapping = async () => {
            await refreshModels();
            try {
                if (configuration.value.concept && configuration.value.domain) {
                    // fetching original concept
                    const concept = await exec(ModelAPI.conceptTree(configuration.value.concept.id)).then(
                        (res: any) => {
                            return res.data;
                        },
                    );

                    // retrieves a map where the key is the old id and the value is the new concept
                    const idMappings = await exec(ModelAPI.domainLatestMapping(configuration.value.domain.id)).then(
                        (res: any) => {
                            return res.data;
                        },
                    );

                    const conceptRef = computed(() => idMappings[concept.id]);

                    // get upgraded mapping
                    const { migrate } = useMappingMigration(sample, conceptRef, basePath);
                    const upgradedMapping = migrate(
                        configuration.value.domain,
                        configuration.value.concept,
                        configuration.value.fields,
                        idMappings,
                        idMappings[concept.id].status === Status.Deprecated,
                    );
                    configuration.value.fields = upgradedMapping.fields;
                    configuration.value.domain = upgradedMapping.domain;
                    configuration.value.concept = upgradedMapping.concept;

                    // mapping has changed, clear processed sample
                    clearProcessedSample();

                    validate();
                    await exec(
                        JobsAPI.updateStep(mapping.value.id, {
                            configuration: configuration.value,
                            serviceVersion: process.env.VUE_APP_TRANSFORMER_VERSION,
                            processedSample: null,
                        }),
                    ).then((res: any) => (mapping.value = res.data));

                    if (idMappings[concept.id].status !== Status.Deprecated) {
                        (root as any).$toastr.s(
                            'Mapping upgraded to the latest version of the model successfully',
                            'Success',
                        );
                        showWarningAboutDeprecatedFields(upgradedMapping.deprecatedFields);
                    } else {
                        (root as any).$toastr.w(
                            `Mapping reset because concept '${S.sanitizeHtml(
                                concept.name,
                            )}' is deprecated in the latest version of the model`,
                            'Warning',
                        );
                        previous();
                    }
                    // Refetch job - this also triggers the watcher and the refresh() is also calledi
                    refetch();
                    resetModelBrowser();
                } else {
                    throw new Error('Concept not defined!');
                }
            } catch (e) {
                (root as any).$toastr.e('Upgrading mapping to the latest version of the model failed', 'Failed');
            }
        };

        const deprecateFields = async (deprecatedConceptIds: number[]) => {
            clearProcessedSample();
            const deprecatedNames: string[] = [];
            for (let f = 0; f < configuration.value.fields.length; f++) {
                const field = configuration.value.fields[f];
                if (field.target.id && field.target.title && deprecatedConceptIds.includes(field.target.id)) {
                    deprecatedNames.push(field.target.title);
                    configuration.value.fields[f] = initEmptyField(field.source);
                }
            }
            showWarningAboutDeprecatedFields(deprecatedNames);

            await exec(
                JobsAPI.updateStep(mapping.value.id, {
                    configuration: configuration.value,
                    serviceVersion: process.env.VUE_APP_TRANSFORMER_VERSION,
                    processedSample: null,
                }),
            ).then((res: any) => {
                mapping.value = res.data;
                configuration.value = mapping.value.configuration;
            });
        };

        /**
         * Remove any stats/ failed transformations related to the mapping the user has just modified or removed
         */
        const removeInvalidTransformation = (sourceId: any) => {
            if (stats.value?.latestExecutionStats?.statsPerField?.length) {
                stats.value.latestExecutionStats.statsPerField = stats.value?.latestExecutionStats?.statsPerField?.filter(
                    (s) => s.id !== sourceId,
                );
            }
        };

        // Remove 'modified' property and invalid transformation (if exists) of a removed mapping
        const clearMapping = (sourceId: any, target: any, transformation: any, full: boolean = true) => {
            configuration.value.fields = configuration.value.fields.map(
                (field: { source: any; target: any; transformation?: any; temp: any }) => {
                    if (field.source.id === sourceId) {
                        if (full) return initEmptyField(field.source);
                        else
                            return {
                                ...field,
                                target: { ...field.target, id: null, title: null, type: null },
                                temp: { ...field.temp, invalid: false },
                            };
                    }
                    // If other concepts with the same target id and path exist then reset their order
                    else {
                        const { id, path: targetPath } = field.target;
                        if (target?.id === id && R.equals(target?.path, targetPath))
                            if (field.transformation?.order) {
                                // If order is greater than concepts count and is not 1 then decrease it
                                if (
                                    field.transformation?.order !== 1 &&
                                    field.transformation?.order > transformation.order
                                )
                                    field.transformation.order--;
                            }
                    }

                    return field;
                },
            );
            if (mapping.value.status === StatusCode.Update) {
                const fieldMapping: any = configuration.value.fields.find((field: any) => field.source.id === sourceId);
                if (fieldMapping && 'modified' in fieldMapping.temp) {
                    delete fieldMapping.temp.modified;
                }
                removeInvalidTransformation(sourceId);
            } else {
                revisedMapping(sourceId);
            }
        };

        /**
         * Deletes the 'modified' property from the previous/ current mapping configurations
         * in order to be able to properly compare them to identify any changes
         */
        const removePropertyFromMapping = (fieldId: any, config: any) => {
            // remove the 'modified' property from the already existing mapping
            const mappingExists: any = config.fields.filter((field: any) => field.source.id === fieldId);

            const clonedMapping = R.clone(mappingExists[0]);
            if (clonedMapping && 'modified' in clonedMapping.temp) {
                delete clonedMapping.temp.modified;
            }

            return clonedMapping;
        };

        const revisedMapping = (fieldId: any) => {
            processedSample.value = null;
            // remove any errors after sample run
            if (validationErrorPerId.value[fieldId]?.type === 'sample') {
                validationErrorPerId.value[fieldId] = { message: null, description: null };
                const mappedField = configuration.value.fields.find((field: any) => field.source.id === fieldId);
                if (mappedField) mappedField.temp.invalid = false;
            }
            if (fieldId && mapping.value && mapping.value.status === StatusCode.Update) {
                // remove the 'modified' property from the already existing mapping
                const clonedMappingAlreadyExists = removePropertyFromMapping(fieldId, mapping.value.configuration);
                // remove the 'modified' property from the new mapping
                const clonedNewlyAddedMapping = removePropertyFromMapping(fieldId, configuration.value);

                const hasDifference =
                    JSON.stringify(clonedNewlyAddedMapping) !== JSON.stringify(clonedMappingAlreadyExists);

                /**
                 * - Add the 'modified' property only if this mapping already existed and was saved and there is a change i.e. in the mapping details
                 * - If the user changes the field and then changes it back to the old value, then it will stay as 'modified'
                 */
                if (
                    clonedMappingAlreadyExists &&
                    clonedMappingAlreadyExists.target.id &&
                    clonedMappingAlreadyExists.target.id === clonedNewlyAddedMapping.target.id &&
                    hasDifference
                ) {
                    const idx: any = configuration.value.fields.findIndex((field: any) => field.source.id === fieldId);

                    if (idx >= 0 && configuration.value.fields[idx].temp) {
                        configuration.value.fields[idx].temp.modified = true;
                    }
                    removeInvalidTransformation(fieldId);
                }
            }
        };

        const refresh = () => {
            exec(JobsAPI.getStep(jobId, 'mapping')).then(async (res: any) => {
                mapping.value = res.data;
                processedSample.value = mapping.value.processedSample;
                configuration.value = mapping.value.configuration;
                if (!previousProcessedSample.value) previousProcessedSample.value = await getPreviousProcessedSample();
                if (
                    ![StatusCode.Configuration, StatusCode.Update].includes(mapping.value.status) &&
                    !isStreamingJob.value
                )
                    getStats();
            });
        };

        const getStats = () => {
            exec(MonitoringAPI.taskStats(job.value.workflowId, mapping.value.taskId)).then((res: any) => {
                stats.value = res.data;
            });
        };

        const conceptChanged = (field: any, concept: any, prediction: any) => {
            setConcept(field, concept, prediction);
            if (activeTab.value === 1) revisedMapping(field.source.id);
        };

        const updateProcessedSample = async (sampleData: any, sampleStats: any = null) => {
            processedSample.value = sampleData;
            if (sampleData) {
                next();
            } else {
                await save(false);
                if (sampleStats) {
                    const failedStats = (sampleStats?.stats_per_field).filter((s: any) => !R.isNil(s.error_code));
                    failedStats.forEach((s: any) => {
                        const mappedField: any = configuration.value.fields.find(
                            (field: FieldConfiguration) => field.source.id === s.id,
                        );
                        if (!mappedField) return;
                        const executionError = errorMessage(s.error_code as number).error;
                        validationErrorPerId.value[s.id] = {
                            message: 'Failed on Sample',
                            title: executionError.title,
                            description: executionError.description,
                            type: 'sample',
                        };
                        mappedField.temp.invalid = true;
                    });
                }
            }
        };

        const { loadingSampleRun, executeSampleRun, skipSampleRun, onMessage } = useSampleRun(
            mapping,
            job,
            root,
            updateProcessedSample,
        );

        const runValidation = () => {
            const valid = validate();
            if (valid) (root as any).$toastr.i('Mapping configuration is valid', 'Success');
            validationTimestamp.value = new Date();
        };

        const runOnSample = async () => {
            const valid = validate();
            if (!valid) {
                (root as any).$toastr.e(
                    'Please address the validation errors before performing a Sample Run',
                    'Validation Failed',
                );
                return;
            }
            await save(false);
            executeSampleRun();
        };

        if (skipSampleRun.value) {
            steps.value = steps.value.filter((step: any) => step.key !== 'rules');
            finalTabNumber.value = 2;
        }

        // When job is ready, retrieve mapping step
        watch(
            () => job.value,
            async () => {
                refresh();
            },
        );

        watch(
            () => [configuration.value, concepts.value, modelReady.value],
            async () => {
                if (!modelReady.value) return;
                // do nothing in case mapping has not been retrieved yet
                if (!configuration.value || !mapping.value) return;
                // do nothing if step is finalised
                if (![StatusCode.Configuration, StatusCode.Update].includes(mapping.value.status)) return;
                // do nothing if not in actual mapping configuration
                if (activeTab.value !== 1) return;
                // do nothing if concepts currently loaded from model browser is not the selected for this mapping (are fetched asynchrously later on)
                if (mappingNeedsUpgrade.value) return;

                const validConfiguration = getValidConfiguration(configuration.value);

                // if current configuration is not valid and there is no pending upgrade
                // then fix up configuration based on valid one
                if (JSON.stringify(validConfiguration) !== JSON.stringify(configuration.value)) {
                    if (rootConcept.value)
                        (root as any).$toastr.w(
                            'Some mapped fields seem to be using concepts that are no longer available. These mappings are now removed from your mapping. Please save to apply the change!',
                            'Warning',
                        );
                    configuration.value = validConfiguration;
                    processedSample.value = null;
                    await updateProcessedSample(processedSample.value);
                }
            },
        );

        // If changes detected, clear current processed sample so to execute new sample run
        watch(
            () => hasChanges.value,
            async (changes: boolean) => {
                const isAnnotationChange =
                    JSON.stringify(removeFields(originalConfiguration.value, ['annotation', 'alias'])) ===
                    JSON.stringify(removeFields(configuration.value, ['annotation', 'alias']));
                if (changes && activeTab.value === 1 && !isAnnotationChange) {
                    processedSample.value = null;
                }
            },
        );

        watch(
            () => rootConcept.value,
            (newRootConcept: any, oldRootConcept: any) => {
                if (newRootConcept && oldRootConcept?.id !== newRootConcept.id) {
                    store.dispatch.modelBrowser.setRestrictingConcept({
                        concept: newRootConcept.id,
                    });
                }
            },
        );

        watch(
            () => configuration.value,
            (newConfiguration: MappingConfig) => {
                const domain: Model | null = newConfiguration?.domain?.id
                    ? getModelFromId(newConfiguration?.domain?.id)
                    : null;

                if (domain && (!model.value || domain.uid !== model.value.uid)) {
                    store.dispatch.modelBrowser.setModel({
                        model: domain.uid,
                    });
                }
            },
            { deep: true },
        );

        watch(
            () => originalConfiguration.value,
            (newConfiguration: MappingConfig) => {
                if (!isFinalized.value && !root.$route.params.step) {
                    activeTab.value = newConfiguration.domain && newConfiguration.concept ? 1 : 0;
                }
                loadingStepKey.value = false;
            },
        );

        watch(
            () => mapping.value,
            async () => {
                if (mapping.value?.status === StatusCode.Update) await refreshMapping();
            },
        );

        watch(
            () => concepts.value,
            async (modelConcepts: any) => {
                const deprecatedFields = [];
                // only if a high level concept is already selected and it's the same as the one already existed in the mapping config.
                if (modelConcepts.length && restrictingConcept.value?.id == mapping.value?.configuration?.concept?.id) {
                    for (let f = 0; f < configuration.value.fields.length; f++) {
                        const field = configuration.value.fields[f];

                        if (!R.isNil(field.target.id)) {
                            const conceptForField = getConceptFromId(field.target.id);
                            if (!conceptForField) {
                                deprecatedFields.push(field.target.id);
                            }
                        }
                    }
                    if (deprecatedFields.length) deprecateFields(deprecatedFields);
                }
            },
        );

        const { subscribe, unsubscribe, WebSocketsEvents, leaveSocketRoom, WebSocketsRoomTypes } = useSockets();

        onBeforeUnmount(() => {
            unsubscribe(WebSocketsEvents.Workflow);
            leaveSocketRoom(WebSocketsRoomTypes.Workflow, job.value?.workflowId);
        });

        onMounted(async () => {
            if (!isConfigEmpty(mapping.value)) await next();
            subscribe(WebSocketsEvents.Workflow, (msg: any) => onMessage(msg));
        });

        onUnmounted(async () => {
            await exec(JobsAPI.unlock(Number(props.id)));
        });

        return {
            mappingConfiguration,
            validationErrorPerId,
            activeTab,
            allowNext,
            cancel,
            concepts,
            stats,
            domains,
            error,
            finalize,
            hasChanges,
            isFinalized,
            isValid,
            job,
            jobError,
            jobLoading,
            loading,
            mapping,
            mappingRef,
            model,
            next,
            previous,
            save,
            clearMappingAndProceed,
            showConfirmModalForClearingMapping,
            steps,
            runValidation,
            showFinalizeModal,
            nextStep,
            mappedConceptsExist,
            isMacOS,
            mappingNeedsUpgrade,
            upgradeMapping,
            deprecateFields,
            StatusCode,
            restartStep,
            canRestart,
            revisedMapping,
            clearMapping,
            invalidMappingsFixed,
            restartedStep,
            pageLoading,
            basePath,
            configuration,
            conceptChanged,
            discardChangesAndProceed,
            validationTimestamp,
            processedSample,
            loadingSampleRun,
            runOnSample,
            finalTabNumber,
            skipSampleRun,
            conceptsLoading,
            domainLoading,
            resettingMapping,
            loadingStepKey,
            lazyFieldChanged,
            showResetAliasesModal,
            clearAliasesAndProceed,
            previousProcessedSample,
            sample,
        };
    },
});
