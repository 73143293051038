

































































































































import { TwButton, WizardTabs, ProcessedSampleView, AlertBanner } from '@/app/components';
import { useAxios, useErrors, useJsonObject, useQuery, useResult, useSockets } from '@/app/composable';
import { StatusCode } from '@/modules/data-checkin/constants';
import { computed, defineComponent, onBeforeUnmount, onMounted, onUnmounted, ref, watch } from '@vue/composition-api';
import { OrbitSpinner } from 'epic-spinners';
import * as R from 'ramda';
import { JobsAPI } from '../../api';
import { LoadingSampleRunModal, StepCompletionModal } from '../../components';
import { useSampleFields, useSampleRun, useStep } from '../../composable';
import GET_JOB from '../../graphql/getJob.graphql';
import AnonymisationConfiguration from './Configuration.vue';
import ReviewAndReport from './ReviewAndReport.vue';
import { v4 as uuidv4 } from 'uuid';
import { MonitoringAPI } from '@/app/api';

export default defineComponent({
    name: 'Anonymisation',
    metaInfo() {
        return { title: `Anonymisation${(this as any).job ? ` for: ${(this as any).job.name}` : ''}` };
    },
    props: {
        id: {
            type: [Number, String],
            required: true,
        },
        queryParams: {
            type: String,
            default: '{}',
        },
    },
    components: {
        AnonymisationConfiguration,
        ReviewAndReport,
        OrbitSpinner,
        WizardTabs,
        TwButton,
        StepCompletionModal,
        ProcessedSampleView,
        LoadingSampleRunModal,
        AlertBanner,
    },
    setup(props, { root }) {
        const { loading, error, exec } = useAxios(true);
        const jobId = parseInt(`${props.id}`, 10);
        const steps = ref([
            { title: 'Configuration', key: 'configuration' },
            { title: 'Review Rules', key: 'rules' },
            { title: 'Review and Report', key: 'confirm' },
        ]);
        const showFinalizeModal = ref<boolean>(false);
        const restartedStep = ref<boolean>(false);
        const activeTab = ref(0);
        const nextStep = ref<any>(null);
        const hasChanges = ref<boolean>(false);
        const currentAnonymisationConfiguration: any = ref(null);
        const stats = ref<any>(null);
        const errorCode = ref<number | null>(null);
        const restarting = ref(false);
        const finalTabNumber = ref<number>(2);
        const alternateNames = ref<any>(null);
        const { extractMappingFieldNames, extractAlternateNames } = useSampleFields();

        const previousProcessedSample = ref<any>(null);
        const processedSample = ref<any>(null);

        const loadingFinalization = ref<boolean>(false);

        // Fetch job information
        const { checkGQLAuthentication } = useErrors(root.$route);
        const { loading: jobLoading, error: jobError, result, onError, refetch } = useQuery(
            GET_JOB,
            {
                id: jobId,
            },
            { fetchPolicy: 'no-cache' },
        );
        onError(checkGQLAuthentication);
        const job = useResult(result, null, (data: any) => data.job);

        const { getFixedJSON } = useJsonObject();

        const fixedSample = computed(() => {
            if (job.value && job.value.sample) {
                return getFixedJSON(job.value.sample);
            }
            return [];
        });

        // Fetch anonymisation configuration
        const anonymisation = ref<any>(null);
        const {
            isConfigEmpty,
            isFinalized,
            getNextStep,
            updateAssetAfterFailedStep,
            canRestart,
            setupUpdatedConfiguration,
            getPreviousProcessedSample,
        } = useStep(anonymisation, job, root);

        const updateProcessedSample = async (sampleData: any) => {
            processedSample.value = sampleData;
            if (sampleData) {
                next();
            } else {
                await save(false);
            }
        };

        const { loadingSampleRun, executeSampleRun, skipSampleRun, onMessage } = useSampleRun(
            anonymisation,
            job,
            root,
            updateProcessedSample,
        );

        const runOnSample = async () => {
            await save(false);
            executeSampleRun();
        };

        if (skipSampleRun.value) {
            steps.value = steps.value.filter((step: any) => step.key !== 'rules');
            finalTabNumber.value = 1;
        }

        // Default (empty) configuration
        const configuration = ref<any>({
            kAnonymity: 2,
            lossLimit: 70,
            fields: [],
        });

        const isValid = computed<boolean>(
            () => configuration.value.fields.filter((obj: any) => obj.temp && obj.temp.invalid).length === 0,
        );

        const next = () => {
            if (activeTab.value === 0 && !skipSampleRun.value && !processedSample.value) {
                runOnSample();
            } else {
                activeTab.value += 1;
            }
        };
        const previous = () => {
            activeTab.value -= 1;
        };

        const save = async (notify: boolean = true) => {
            try {
                await exec(
                    JobsAPI.updateStep(anonymisation.value.id, {
                        configuration: configuration.value,
                        serviceVersion: process.env.VUE_APP_ANONYMISER_VERSION,
                        processedSample: processedSample.value,
                    }),
                );
                if (notify) (root as any).$toastr.s('Anonymisation configuration saved successfuly', 'Success');
                hasChanges.value = false;
            } catch (e) {
                (root as any).$toastr.e('Saving anonymisation configuration failed', 'Failed');
                hasChanges.value = true;
            }
        };

        const finalize = () => {
            if (isValid.value) {
                loadingFinalization.value = true;
                exec(
                    JobsAPI.updateStep(anonymisation.value.id, {
                        configuration: configuration.value,
                        serviceVersion: process.env.VUE_APP_ANONYMISER_VERSION,
                    }),
                ).then(() => {
                    getNextStep().then(async (stepTypeResponse: any) => {
                        nextStep.value = stepTypeResponse;

                        /**
                         * If loader step (order = 100) has a different status than "configuration",
                         * it means that the Asset has already been created
                         */
                        if (
                            anonymisation.value.status === StatusCode.Update &&
                            nextStep.value.order === 100 &&
                            nextStep.value.status !== StatusCode.Configuration
                        ) {
                            refetch(); // refetch job with its steps after the anonymisation step is updated
                            if (job.value?.asset && job.value.asset.id) {
                                await updateAssetAfterFailedStep(job.value);
                                await exec(JobsAPI.finalize(anonymisation.value.id));
                                restartedStep.value = true;
                            } else {
                                (root as any).$toastr.e(
                                    'Failed finalising revised Anonymisation step due to an error',
                                    'Failed',
                                );
                            }
                        } else {
                            await exec(JobsAPI.finalize(anonymisation.value.id));
                            showFinalizeModal.value = true;
                        }
                        loadingFinalization.value = false;
                    });
                });
            }
        };

        const stepStatus = computed(() =>
            anonymisation.value && anonymisation.value.status ? anonymisation.value.status : StatusCode.Configuration,
        );

        const getStep = () => {
            exec(JobsAPI.getStep(jobId, 'anonymiser')).then(async (resAnonymisation: any) => {
                anonymisation.value = resAnonymisation.data;
                processedSample.value = resAnonymisation.data.processedSample;
                if (!previousProcessedSample.value) previousProcessedSample.value = await getPreviousProcessedSample();

                // retrieve mapping configuration and extract the mapped fields
                exec(JobsAPI.getStep(jobId, 'mapping')).then(async (resMapping: any) => {
                    //TODO: If cleaning step exists, get cleaning step's processed sample.
                    alternateNames.value = extractAlternateNames(resMapping.data.configuration);
                    if (!isConfigEmpty(resMapping.data.configuration)) {
                        if (isConfigEmpty(resAnonymisation.data.configuration)) {
                            const mappingConfig = resMapping.data.configuration;
                            const mappingFields = extractMappingFieldNames(mappingConfig.fields);
                            mappingFields.forEach((field: any) => {
                                const obj = R.clone(field);
                                obj.anonymisationType = 'insensitive';
                                obj.generalisation = null;
                                obj.options = null;
                                obj.anonymisationIdentifier = uuidv4();
                                configuration.value.fields.push(obj);
                            });
                        } else {
                            configuration.value = R.clone(resAnonymisation.data.configuration);

                            if (anonymisation.value.status === StatusCode.Update) {
                                /**
                                 * check if any fields have been added/ removed/ modified after
                                 * revised mapping and update the anonymisation configuration
                                 */
                                configuration.value = await setupUpdatedConfiguration(
                                    resMapping.data.configuration.fields,
                                    configuration.value,
                                );
                                currentAnonymisationConfiguration.value = R.clone(configuration.value);

                                // If there is already a processed sample (e.g. after cloning),
                                // reset it if the number of anonymised fields changed or if the old
                                // anonymised fields changed
                                if (processedSample.value) {
                                    if (
                                        resAnonymisation.data.configuration.fields.length !==
                                        currentAnonymisationConfiguration.value.fields.length
                                    )
                                        processedSample.value = null;
                                    else {
                                        const unchangedFields = currentAnonymisationConfiguration.value.fields.filter(
                                            (cf: any) =>
                                                resAnonymisation.data.configuration.fields.find(
                                                    (f: any) =>
                                                        cf.id === f.id &&
                                                        cf.parentIds.every(
                                                            (id: number, idx: number) => id === f.parentIds[idx],
                                                        ),
                                                ),
                                        );
                                        if (
                                            unchangedFields.length !==
                                            currentAnonymisationConfiguration.value.fields.length
                                        )
                                            processedSample.value = null;
                                    }
                                }
                            }
                        }
                    }
                    if (![StatusCode.Configuration, StatusCode.Update].includes(anonymisation.value.status)) getStats();
                });
            });
        };

        const getStats = () => {
            exec(MonitoringAPI.taskStats(job.value.workflowId, anonymisation.value.taskId)).then((res: any) => {
                stats.value = res.data;
            });
        };

        const changeKAnonymity = (k: string) => {
            configuration.value.kAnonymity = parseInt(k, 10);
        };

        const changeLossLimit = (limit: string) => {
            configuration.value.lossLimit = parseInt(limit, 10);
        };

        const restartStep = () => {
            restarting.value = true;
            try {
                exec(JobsAPI.restartStep(anonymisation.value.id)).then((res: any) => {
                    anonymisation.value = res.data;
                    currentAnonymisationConfiguration.value = anonymisation.value.configuration;

                    restarting.value = false;
                    (root as any).$toastr.s(
                        'The configuration of the anonymisation step is now available for updates.',
                        'Success',
                    );
                });
            } catch (e) {
                (root as any).$toastr.e('Revising of the configuration of the anonymisation step failed', 'Failed');
            }
        };

        const hasDifferenceInConfiguration = computed(() => {
            if (
                anonymisation.value &&
                anonymisation.value.status === StatusCode.Update &&
                anonymisation.value.message &&
                currentAnonymisationConfiguration.value
            ) {
                return JSON.stringify(currentAnonymisationConfiguration.value) !== JSON.stringify(configuration.value);
            }

            return true;
        });

        const pageLoading = computed(() => {
            return loadingFinalization.value || loading.value || jobLoading.value;
        });

        const { subscribe, unsubscribe, WebSocketsEvents, leaveSocketRoom, WebSocketsRoomTypes } = useSockets();

        const unlockJob = async () => {
            await exec(JobsAPI.unlock(Number(props.id)));
        };

        const onFieldChange = () => {
            hasChanges.value = true;
            processedSample.value = null;
        };

        onMounted(async () => {
            if (!isConfigEmpty(anonymisation.value)) await next();
            subscribe(WebSocketsEvents.Workflow, (msg: any) => onMessage(msg));

            window.addEventListener('beforeunload', unlockJob);
        });

        onUnmounted(async () => {
            unlockJob();
        });

        onBeforeUnmount(() => {
            unsubscribe(WebSocketsEvents.Workflow);
            leaveSocketRoom(WebSocketsRoomTypes.Workflow, job.value?.workflowId);
        });

        // When job is ready, retrieve anonymisation step
        watch(
            () => job.value,
            async () => getStep(),
        );

        return {
            anonymisation,
            activeTab,
            jobLoading,
            jobError,
            job,
            jobId,
            steps,
            isFinalized,
            save,
            finalize,
            next,
            previous,
            loading,
            error,
            showFinalizeModal,
            nextStep,
            configuration,
            isValid,
            hasChanges,
            changeKAnonymity,
            changeLossLimit,
            stats,
            errorCode,
            fixedSample,
            canRestart,
            restartStep,
            restarting,
            hasDifferenceInConfiguration,
            restartedStep,
            pageLoading,
            stepStatus,
            loadingSampleRun,
            processedSample,
            skipSampleRun,
            finalTabNumber,
            alternateNames,
            onFieldChange,
            previousProcessedSample,
            runOnSample,
        };
    },
});
