





































































































































































































































































































































































































































































































































































import { AlertBanner, ConfirmModal, FormBlock, JsonParser, TwButton } from '@/app/components';
import { useAxios, useFilters, useJsonObject, useSchedule } from '@/app/composable';
import { requiredValidator } from '@/app/validators';
import { KafkaAPI } from '@/modules/data-checkin/api';
import { ChevronRightIcon } from '@vue-hero-icons/solid';
import { computed, defineComponent, reactive, ref, watch } from '@vue/composition-api';
import dayjs from 'dayjs';
import minMax from 'dayjs/plugin/minMax';
import utc from 'dayjs/plugin/utc';
import { OrbitSpinner } from 'epic-spinners';
import * as R from 'ramda';
import { ValidationObserver, ValidationProvider, extend } from 'vee-validate';
import ClickOutside from 'vue-click-outside';
import { ProcessingOptions, RetrievalSettings } from '../../components';
import { useHarvester } from '../../composable';
import { ProcessingOptions as ProcOptions, SAMPLE_LIMIT } from '../../constants';

const { formatBytes } = useFilters();

extend('required', requiredValidator);

dayjs.extend(minMax);
dayjs.extend(utc);

export default defineComponent({
    name: 'ExternalKafkaConfiguration',
    model: {
        prop: 'configuration',
    },
    props: {
        configuration: {
            type: Object,
            required: true,
        },
        sample: {
            type: [Object, Array],
            required: false,
        },
        files: {
            type: Object,
            required: true,
        },
        activeTab: {
            type: Number,
            required: true,
        },
        completed: {
            type: Boolean,
            default: true,
        },
        isFinalized: {
            type: Boolean,
            default: false,
        },
        jobConfig: {
            type: Object,
            required: false,
        },
        basePath: {
            type: String,
            required: true,
        },
        running: {
            type: Boolean,
            default: true,
        },
        isDeprecated: {
            type: Boolean,
            default: false,
        },
        workflowId: {
            type: String,
            required: true,
        },
        jobCreatedById: {
            type: Number,
        },
        pipelineFinalized: {
            type: Boolean,
            required: true,
        },
        stepId: {
            type: Number,
            required: true,
        },
        isOnUpdate: {
            type: Boolean,
            default: false,
        },
        loadingFinalization: {
            type: Boolean,
            default: false,
        },
    },
    directives: {
        ClickOutside,
    },
    components: {
        FormBlock,
        ValidationProvider,
        JsonParser,
        ValidationObserver,
        TwButton,
        OrbitSpinner,
        ProcessingOptions,
        RetrievalSettings,
        ConfirmModal,
        AlertBanner,
        ChevronRightIcon,
    },
    setup(props, { root, emit }) {
        const showPartialMatchModal = ref<boolean>(false);
        const showNoMatchModal = ref<boolean>(false);
        const schedulesToBeDeleted = ref<string[]>([]);
        const separator = '||';
        const schedules = ref([]);
        const finalSample = ref<any>(props.configuration.processedSample);
        const { loading, exec } = useAxios(true);
        const sampleFile = computed(() => props.files.sample);
        const sampleRef = ref<any>(null);
        const kafkaValidationRef = ref<any>(null);
        const completedStep = computed(() => props.completed);
        const isSampleArray = computed(() => R.is(Array, props.configuration.response.data));

        const { validStreamingSchedules, validEndDate, endDateError, streamingSchedulesError } = useSchedule();

        const { getAllPaths } = useJsonObject();

        const errorAlert: any = reactive({
            title: null,
            body: null,
            showIgnore: false,
        });

        const emptySampleResponse = ref<boolean>(false);
        const retrieveNewFileTypeSample = ref<boolean>(false);

        const securityProtocols = ['PLAINTEXT', 'SSL', 'SASL_PLAINTEXT', 'SASL_SSL'];
        const saslMechanisms = ['None', 'PLAIN', 'SCRAM-SHA-256', 'SCRAM-SHA-512'];

        const connectionDetailsAlreadySaved = ref<any>(R.clone(props.configuration.connectionDetails));

        const {
            changeFinalSample,
            limitResponse,
            parseJSON,
            checkInvalidXML,
            parseXML,
            parseXMLString,
            clearFiles,
            reduceSampleValues,
            invalidFormat,
        } = useHarvester(root, emit);

        const modifyFinalSample = (sample: any) => {
            finalSample.value = sample;
            changeFinalSample(sample, props.configuration.source);
        };

        const acceptedFiles = computed(() => {
            switch (props.configuration.fileType) {
                case 'json':
                    return '.json';
                case 'xml':
                    return '.xml';
                default:
                    return '.*';
            }
        });

        const modifiedErrorMessage = (error: any) => {
            const startsWith = error ? error.message.split(' ').slice(0, 2).join(' ') : null;
            errorAlert.title = 'Failed action';
            if (error.message) {
                if (error.message.includes('group coordinator')) {
                    errorAlert.body = `Failed to find Group Id ${props.configuration.connectionDetails.groupId}. Please update the connection details to proceed.`;
                    return;
                }

                switch (startsWith) {
                    case 'SASL SCRAM':
                        errorAlert.body = `Authentication failed due to invalid credentials with SASL mechanism ${props.configuration.connectionDetails.saslMechanism}. Please update the connection details to proceed.`;
                        break;
                    case 'SASL NONE':
                        errorAlert.body = `Authentication failed. Please update the connection details to proceed.`;
                        break;
                    case 'Connection error:':
                    case 'Failed to':
                        errorAlert.body = `Connecting to ${props.configuration.connectionDetails.url} failed. Please update the connection details to proceed.`;
                        break;
                    case 'Not authorized':
                        errorAlert.body = `Not authorised to access topic ${props.configuration.connectionDetails.topic}. Please update the connection details to proceed.`;
                        break;
                    default:
                        errorAlert.body = error ? `Testing failed with message: ${error.message}` : null;
                }
            }
        };

        const selection = computed(() =>
            props.configuration.response.selectedItems.filter((item: string) => item.includes(separator)),
        );

        const connectionDetailsChanged = computed(
            () =>
                JSON.stringify(connectionDetailsAlreadySaved.value) !==
                JSON.stringify(props.configuration.connectionDetails),
        );

        const sampleFileIsRequired = computed(
            () =>
                !sampleFile.value &&
                !props.configuration.isSampleUploaded &&
                emptySampleResponse.value &&
                !connectionDetailsChanged.value,
        );

        const testCredentialsAndCreateSample = () => {
            const connectionDetails = props.configuration.connectionDetails;
            const fileType = props.configuration.fileType;

            exec(KafkaAPI.testCredentialsAndCreateSample(connectionDetails, fileType))
                .then((res: any) => {
                    finalSample.value = null;
                    if (!res.data || (fileType === 'json' && !res.data.length)) {
                        emptySampleResponse.value = true;
                        errorAlert.title = 'Failed to retrieve sample.';
                        errorAlert.body =
                            'Please upload a sample in the "Sample Streaming Data Upload" section below. Important note: the sample must be an exact match of the messages that will be published in the specific kafka topic.';
                    } else {
                        const data = fileType === 'xml' ? parseXMLString(res.data.toString()) : res.data;
                        props.configuration.response.data = reduceSampleValues(limitResponse(data, 20)); // eslint-disable-line no-param-reassign
                        emit('sample-consumed', props.configuration.response.data);
                        if (
                            (!props.isOnUpdate || props.configuration.response.isReset) &&
                            props.configuration.response.selectedItems
                        )
                            props.configuration.response.selectedItems.splice(0);
                        if (
                            props.configuration.response.data &&
                            props.isOnUpdate &&
                            selection.value.length &&
                            !props.configuration.response.isReset
                        ) {
                            const allPaths = getAllPaths(props.configuration.response.data, '', 'res', separator);
                            const missingFields = selection.value.filter((field: string) => !allPaths.includes(field));
                            if (missingFields.length === selection.value.length) {
                                showNoMatchModal.value = true;
                                return;
                            } else if (missingFields.length) {
                                showPartialMatchModal.value = true;
                                return;
                            }
                        }
                        emit('next-tab');
                    }
                    emit('save-changes');
                })
                .catch((error) => {
                    if (error && error.response.data) modifiedErrorMessage(error.response.data);
                    (root as any).$toastr.e('PubSub mechanism connection failed', 'Error');
                });
        };

        const validateAndProceed = async () => {
            if (!kafkaValidationRef.value) return;

            const valid = await kafkaValidationRef.value.validate();
            if (!valid) return;

            if (!props.pipelineFinalized) {
                if (props.configuration.processing === ProcOptions.TimeBased) {
                    const schedulesAreValid = validStreamingSchedules(schedules.value);
                    if (!schedulesAreValid) {
                        (root as any).$toastr.e(streamingSchedulesError.description, streamingSchedulesError.title);
                        return;
                    }
                } else {
                    const endDateIsValid = validEndDate(props.configuration.retrieval.endDate);
                    if (!endDateIsValid) {
                        (root as any).$toastr.e(endDateError.description, endDateError.title);
                        return;
                    }
                }
            }

            if (
                connectionDetailsChanged.value ||
                (retrieveNewFileTypeSample.value && !props.configuration.isSampleUploaded)
            ) {
                emit('sample-uploaded', null);
                emit('files-changed', { sample: null });
                changeFinalSample(null, props.configuration.source);
                if (
                    (!props.isOnUpdate || props.configuration.response.isReset) &&
                    props.configuration.response.selectedItems
                )
                    props.configuration.response.selectedItems.splice(0);
                finalSample.value = null;
                props.configuration.isSampleUploaded = null; // eslint-disable-line no-param-reassign
                props.configuration.response.data = null; // eslint-disable-line no-param-reassign
                emptySampleResponse.value = false;
                retrieveNewFileTypeSample.value = false;
            }
            emit('update-connection-details', props.configuration.connectionDetails);
            connectionDetailsAlreadySaved.value = R.clone(props.configuration.connectionDetails);
            errorAlert.title = null;
            errorAlert.body = null;
            emptySampleResponse.value = false;
            if (
                !emptySampleResponse.value &&
                !props.configuration.isSampleUploaded &&
                !props.configuration.response.data &&
                !props.isFinalized
            ) {
                testCredentialsAndCreateSample();
            } else {
                // eslint-disable-next-line no-param-reassign
                props.configuration.response.data = limitResponse(props.configuration.response.data, 20);
                emit('next-tab');
            }
        };

        const confirmSelectionChange = (reset: boolean) => {
            if (props.configuration.isSampleUploaded)
                props.configuration.response.data = limitResponse(props.sample, 20);
            props.configuration.response.isReset = true;
            if (reset) props.configuration.response.selectedItems.splice(0);
            else {
                const allPaths = getAllPaths(props.configuration.response.data, '', 'res', separator);
                props.configuration.response.selectedItems = selection.value.filter((field: string) =>
                    allPaths.includes(field),
                );
            }
            showNoMatchModal.value = false;
            showPartialMatchModal.value = false;
            emit('next-tab');
        };

        const cancelSelectionChange = () => {
            if (props.configuration.isSampleUploaded) {
                emit('files-changed', { sample: null });
                emit('sample-uploaded', null);
            }
            props.configuration.response.data = null;
            showPartialMatchModal.value = false;
            showNoMatchModal.value = false;
        };

        const validate = async () => {
            return kafkaValidationRef.value.validate();
        };

        const sampleUploaded = async (event: any) => {
            const file = event.target.files[0];
            if (file.size > SAMPLE_LIMIT) {
                (root as any).$toastr.e(`The sample file should not exceed 500KB.`, 'Sample file too large!');
                emit('files-changed', { sample: null });
                return;
            }
            await emit('sample-cropped', false);
            switch (props.configuration.fileType) {
                case 'json':
                    await emit('files-changed', { sample: file });
                    await parseJSON(file);
                    if (!props.sample) {
                        emptySampleResponse.value = true;
                    }
                    break;
                case 'xml':
                    await checkInvalidXML(file);
                    if (invalidFormat.value) {
                        emptySampleResponse.value = true;
                        emit('files-changed', { sample: null });
                        emit('sample-uploaded', null);
                        (root as any).$toastr.e('Invalid xml format!', 'Error');
                    } else {
                        await emit('files-changed', { sample: file });
                        await parseXML(file);
                    }
                    break;
                default:
                // Do nothing
            }
            props.configuration.response.data = props.sample;
            if (props.sample && props.isOnUpdate && selection.value.length && !props.configuration.response.isReset) {
                const allPaths = getAllPaths(props.sample, '', 'res', separator);
                const missingFields = selection.value.filter((field: string) => !allPaths.includes(field));
                if (missingFields.length === selection.value.length) {
                    showNoMatchModal.value = true;
                    return;
                } else if (missingFields.length) {
                    showPartialMatchModal.value = true;
                    return;
                }
            }
            await validate();
        };

        const jobConfig = computed(() => {
            if (props.configuration.response.data && props.basePath) {
                let selectedItems = [];
                const { basePath }: { basePath: string | null } = props;
                let multiple = false;

                if (props.configuration?.response?.selectedItems) {
                    selectedItems = props.configuration.response.selectedItems;
                }

                if (isSampleArray.value) {
                    multiple = true;
                }

                return {
                    basePath,
                    multiple,
                    selectedItems,
                };
            }

            return null;
        });

        watch(
            () => jobConfig.value,
            (config: any) => {
                if (!props.completed) {
                    emit('job-config-change', config);
                }
            },
        );

        const resetFileFormat = async () => {
            clearFiles();
            await emit('sample-uploaded', null);
            retrieveNewFileTypeSample.value = true;
        };

        const checkSecurityProtocol = () => {
            if (['PLAINTEXT', 'SSL'].includes(props.configuration.connectionDetails.securityProtocol)) {
                props.configuration.connectionDetails.saslMechanism = 'None';
                emit('reset-connection-details');
            }
        };

        const checkSASLMechanism = async () => {
            if (props.configuration.connectionDetails.saslMechanism === 'None') {
                await emit('reset-connection-details');
            }
        };

        const sampleRetrievedFromDescription = computed(() =>
            props.configuration.isSampleUploaded
                ? 'Sample uploaded by the user'
                : 'Kafka response retrieved when testing the Kafka connection',
        );

        const sampleSummaryText = computed(() =>
            props.configuration.isSampleUploaded ? 'Uploaded Sample' : 'Kafka Response',
        );

        const sampleMayBeCroppedMessage = computed(() =>
            props.configuration.fileType
                ? '- Sample may be cropped if required. Ensure that a small sample contains all necessary fields.'
                : '',
        );

        const sampleCroppedMessage = computed(() => (props.configuration.isSampleCropped ? 'cropped, ' : ''));

        const updateSchedules = (updatedSchedules: any) => {
            schedules.value = updatedSchedules;
        };

        const deleteSchedules = (scheduleIds: string[]) => {
            schedulesToBeDeleted.value = scheduleIds;
        };

        const invalidNoOfSchedules = computed(
            () => props.configuration.processing === ProcOptions.TimeBased && !schedules.value.length,
        );

        const maxEndDateForProcessing = computed(() =>
            props.configuration.retrieval.endDate ? new Date(props.configuration.retrieval.endDate) : null,
        );

        const displaySampleIsArrayBanner = computed(
            () =>
                isSampleArray.value &&
                !props.isFinalized &&
                !props.loadingFinalization &&
                props.configuration.isSampleUploaded,
        );

        watch(
            () => connectionDetailsChanged.value,
            async () => {
                if (emptySampleResponse.value) await kafkaValidationRef.value.validate();
            },
        );

        return {
            kafkaValidationRef,
            formatBytes,
            sampleFile,
            sampleRef,
            validate,
            validateAndProceed,
            securityProtocols,
            saslMechanisms,
            isSampleArray,
            separator,
            finalSample,
            modifyFinalSample,
            loading,
            errorAlert,
            sampleUploaded,
            acceptedFiles,
            clearFiles,
            emptySampleResponse,
            retrieveNewFileTypeSample,
            resetFileFormat,
            checkSecurityProtocol,
            checkSASLMechanism,
            sampleRetrievedFromDescription,
            sampleSummaryText,
            sampleCroppedMessage,
            sampleMayBeCroppedMessage,
            updateSchedules,
            invalidNoOfSchedules,
            schedules,
            completedStep,
            maxEndDateForProcessing,
            showPartialMatchModal,
            showNoMatchModal,
            confirmSelectionChange,
            cancelSelectionChange,
            R,
            deleteSchedules,
            schedulesToBeDeleted,
            displaySampleIsArrayBanner,
            ProcOptions,
            connectionDetailsChanged,
            sampleFileIsRequired,
        };
    },
});
