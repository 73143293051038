


































































































































































import { useFilters } from '@/app/composable/filters';
import { ExclamationIcon } from '@vue-hero-icons/outline';
import { computed, defineComponent, ref } from '@vue/composition-api';
import { FieldPath } from '../../components';
import { useAnonymisation } from '../../composable';
import { AnonymisationType, GeneralizationMethod } from '../../constants';

export default defineComponent({
    name: 'FieldBlock',
    components: {
        FieldPath,
        ExclamationIcon,
    },
    props: {
        field: {
            type: Object,
            required: true,
        },
        selected: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        incomplete: {
            type: Array,
            default: null,
        },
        isFinalized: {
            type: Boolean,
            default: false,
        },
        sample: {
            type: Array,
            required: false,
        },
        stats: {
            type: Object,
            required: false,
        },
        modified: {
            type: Boolean,
            default: false,
        },
        alternateName: {
            type: Object,
            default: null,
        },
        showAlternateNaming: {
            type: Boolean,
            default: false,
        },
    },
    setup(props, { emit }) {
        const expanded = ref<boolean>(false);
        const { formatNumber } = useFilters();
        const {
            getNextLevel,
            extractIntervalRules,
            extractNumericalGroupRules,
            extractDatetimeRules,
            extractMaskingRules,
            extractBooleanRules,
        } = useAnonymisation();

        const classes = computed(() => {
            if (props.selected) return 'rounded-r border-primary-700';
            return 'rounded border-white';
        });

        const fieldClicked = () => {
            emit('toggle-selection');
            if (props.disabled) {
                expanded.value = !expanded.value;
            }
        };

        const anonymisationRules = computed(() => {
            const rules: Array<Array<string>> = [];
            let level = null;
            for (let i = 1; i <= props.stats?.anonymisationGeneralisationLevel; i += 1) {
                level = getNextLevel(i, level, props.field);
            }
            if (props.field.anonymisationType === AnonymisationType.QuasiIdentifier) {
                if (props.stats && props.stats.anonymisationGeneralisationLevel === 0) {
                    rules.push(['Field values remained unchanged.']);
                } else {
                    const hasStats = !!props.isFinalized && !!props.stats;
                    switch (props.field.generalisation) {
                        case GeneralizationMethod.Interval:
                            extractIntervalRules(rules, level, hasStats, props.field.options);
                            break;
                        case GeneralizationMethod.NumericalGroup:
                            extractNumericalGroupRules(rules, level, hasStats, props.field.options);
                            break;
                        case GeneralizationMethod.Datetime:
                        case GeneralizationMethod.Date:
                        case GeneralizationMethod.Time:
                            extractDatetimeRules(rules, level, hasStats, props.field.type, props.field.options);
                            break;
                        case GeneralizationMethod.Masking:
                            extractMaskingRules(rules, level, hasStats, props.field.options);
                            break;
                        case GeneralizationMethod.BooleanGroup:
                            extractBooleanRules(rules, hasStats, props.field.options);

                            break;
                        default:
                        // do nothing
                    }
                }
            } else if (props.field.anonymisationType === AnonymisationType.Identifier) {
                if (props.stats) {
                    if (props.field.options.anonymisationMethod === 'drop') {
                        rules.push(['This column was', 'dropped', 'from the dataset.']);
                    } else {
                        rules.push(['Field values were', 'replaced', 'with the character', '*', '.']);
                    }
                } else if (props.field.options.anonymisationMethod === 'drop') {
                    rules.push(['This column will be', 'dropped', 'from the dataset.']);
                } else {
                    rules.push(['Field values will be', 'replaced', 'with the character', '*', '.']);
                }
            } else if (props.field.anonymisationType === AnonymisationType.Sensitive) {
                rules.push([
                    `Field values ${props.stats ? 'remained' : 'will remain'} unchanged. ${
                        !props.stats
                            ? 'The anonymisation algorithm will make sure to protect an individual to be identified by generalising the quasi-identifiers.'
                            : ''
                    }`,
                ]);
            }
            return rules;
        });

        const fieldMethod = computed(() => {
            if (props.field.anonymisationType === AnonymisationType.QuasiIdentifier) {
                return props.field.generalisation;
            }
            if (props.field.anonymisationType === AnonymisationType.Identifier) {
                if (props.field.options.anonymisationMethod === 'drop') return 'Drop Column';
                return 'Hide Value';
            }
            return null;
        });

        return {
            classes,
            expanded,
            fieldClicked,
            anonymisationRules,
            fieldMethod,
            formatNumber,
            AnonymisationType,
        };
    },
});
